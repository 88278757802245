/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import React from 'react'
/* eslint-disable react/no-unknown-property */
 import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import { IconButton } from "@material-tailwind/react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'font-awesome/css/font-awesome.min.css'; 
 

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
 import Glide from "@glidejs/glide"
 
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
const StartupPlanContent = () => {
  return (
   <>
<table class="ml-[-40px] border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
 dark:text-gray-400"> 
      
      
      <tbody>
 {/*   1st  Section  */}
            <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">

            <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[12px] font-normal">App Development <div
    class="text-[8px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
    <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  Android App: Only </span>
  </div>

 </Typography>
           </li>
                   </Typography>
                 </td>
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                    <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
 </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>

           
{/* 3rd section  */}
              <tr>
              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
             Video Ads Creation
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className=" flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal">
           Free Domain & Hosting ( 2 Gb )  
             </Typography>
           </li>
                   </Typography>
                 </td>

              </tr>
              
                {/*  2nd section  */}

                <tr> 
 
   <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through ">
            Digital Marketing 
             </Typography>
           </li>
                   </Typography>
                 </td>



   <td>
     <Typography
       variant="small"
       color="blue-gray"
       className="font-normal"
     >
     <li className="flex items-center gap-4">
<span className="rounded-full border border-white/20 bg-teal-400 p-1">
 <CheckIcon />
</span>
<Typography className="text-white  text-[14px] font-normal">
Banner Ads    
</Typography>
</li>
     </Typography>
   </td>

</tr>

{/* 4th section  */}
              <tr>

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-teal-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal ">
             Upgrade Version+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through ">
             Full Stack Feature+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              
              {/* 5th Section */} 

              <tr >

              <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Advanced Customization +
             </Typography>
           </li>
                   </Typography>
                 </td>
 
 
                 <td>
                   <Typography
                     variant="small"
                     color="blue-gray"
                     className="font-normal"
                   >
                   <li className="flex items-center gap-4">
             <span className="rounded-full border border-white/20 bg-red-400 p-1">
               <CheckIcon />
             </span>
             <Typography className="text-white text-[14px] font-normal line-through">
             Company Formation+ 
             </Typography>
           </li>
                   </Typography>
                 </td>
              </tr>
              </tbody>
               
              </table>
   </> 
  );
}

export default StartupPlanContent;
