 /* eslint-disable react/no-unescaped-entities */
 /* eslint-disable no-undef */
 /* eslint-disable no-unused-vars */
 /* eslint-disable react/no-unknown-property */
 import {
   Accordion,
   AccordionHeader,
   AccordionBody,
 } from "@material-tailwind/react";
 import {
   Card,
   CardHeader,
   CardBody,
   CardFooter,
   Typography,
   Button,
 } from "@material-tailwind/react";
 import { IconButton } from "@material-tailwind/react";
 
 import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 import 'font-awesome/css/font-awesome.min.css'; 
 
 
 import Carousel from "react-multi-carousel";
 import "react-multi-carousel/lib/styles.css";
 import React, { useEffect } from "react"
 import Glide from "@glidejs/glide"
 import Startup_plan_content from "../../Plans/Startup_plan_content";
 const responsive = {
   superLargeDesktop: {
     // the naming can be any, depends on you.
     breakpoint: { max: 2000, min: 1000 },
     items: 4
   },
   desktop: {
     breakpoint: { max: 2000, min: 900 },
     items: 3
   },
   tablet: {
     breakpoint: { max: 1024, min: 464 },
     items:1
   },
   mobile: {
     breakpoint: { max: 464, min: 0 },
     items: 1
   }
 };
 function CheckIcon() {
   return (
     <svg
       xmlns="http://www.w3.org/2000/svg"
       fill="none"
       viewBox="0 0 24 24"
       strokeWidth={2}
       stroke="currentColor"
       className="h-3 w-3"
     >
       <path
         strokeLinecap="round"
         strokeLinejoin="round"
         d="M4.5 12.75l6 6 9-13.5"
       />
     </svg>
   );
 }
 export default function Plans() {
   return (
    <>
     
   <section className="mt-[-9px]">
   <div class="mx-auto">
       <div  class="mb-[-26px] text-gray-600 body-font  ">
       <h3 class="text-center lg:text-[22px] font-bold sm:text-2xl"> Our Plans at Hickter     </h3>
         </div>
       </div>
 
       <Carousel responsive={responsive}  className="z-[0] overscroll-x-none lg:p-9 lg:mt-[-10px] p-6 mr-8 " >
 
 
  {/* Startup Plan    */}
  <div className=" p-[2px] mt-[10px]">  
 <Card  variant="gradient" className="p-[12px] bg-blue-900	
      mx-auto rounded ">
           <CardHeader color="blue-gray" className="auto w-cover h-15">
          <img
            src="./assets/plans/Starup Plan.webp"
          />
        </CardHeader>
           
           <CardBody className="mt-[-20px] bg-blue-900" >
             
         <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal">
            <span className="mt-2 ml-2 text-[32px]">₹15,000  </span>{""}
            <span className="self-end text-[14px]">   / Yearly  </span> 
            <div className="text-center" class="flex gap-10">
   <div
      class="ml-5 relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
      <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
        <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
      </div> 
      <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹16,000 </p> </span>  
    </div>
  
  </div>  
          </Typography>
          <br></br>
  <Startup_plan_content/>
 
 
          <Accordion >
           
         <AccordionHeader className="text-white"> </AccordionHeader> 
       </Accordion>
     
       </CardBody>
       <CardFooter className=" mt-[-20px]" >
       <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
        <tr className=" gap-x-2	" >
           <td>
           <a href="/Our_Portfolio"> <Button className="bg-blue-600"> <i style={{fontSize:12}} class="fa-solid fa-eye"></i> View Clients </Button></a>
           </td>
           <td>
           <a href="/Startup_apply">  <Button className="bg-green-500 "> Apply <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
 </td>
 
 
         </tr></div>
        
       </CardFooter>
       
       </Card></div>
 
 {/* Business Plan    */}
 <div className="p-[5px] mt-[10px]">  
 <Card  variant="gradient" className="p-[12px] bg-blue-900	
      mx-auto rounded ">
           <CardHeader color="blue-gray" className="auto w-cover h-15">
          <img
            src="./assets/plans/Business Plan.webp"
          />
        </CardHeader>
           
           <CardBody className="mt-[-20px] bg-blue-900" >
             
         <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal">
            <span className="mt-2 ml-2 text-[32px]">₹30,000  </span>{""}
            <span className="self-end text-[14px]">   / Yearly  </span> 
            <div className="text-center" class="flex gap-10">
   <div
      class="ml-5 relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
      <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
        <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
      </div> 
      <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹40,000 </p> </span>  
    </div>
  
  </div>  
          </Typography>
          <Accordion >
         <AccordionHeader className="text-white"> </AccordionHeader> 
       </Accordion>
   <table class="ml-[-40px] border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
  dark:text-gray-400"> 
       
       <tbody>
  {/*   1st  Section  */}
             <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">
 
             <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">App Development <div
     class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
     <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  Android Only   </span>
   </div>
 
  </Typography>
            </li>
                    </Typography>
                  </td>
   
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                     <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
  </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
 
              {/*  2nd section  */}
 
               <tr> 
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
  </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white  text-[14px] font-normal">
              Banner Ads    
              </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
               
 {/* 3rd section  */}
               <tr>
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">
              Video Ads Creation
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className=" flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">
             Domain & Hosting   
              </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
               
 {/* 4th section  */}
               <tr>
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal ">
              Upgrade Version+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-red-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through ">
              Full Stack Feature+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
               </tr>
               
               {/* 5th Section */} 
 
               <tr >
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-red-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through">
              Advanced Customization +
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-red-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through">
              Company Formation+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
               </tr>
               </tbody>
               
               
               </table>
   
   
       </CardBody>
       <CardFooter className=" mt-[-20px]" >
       <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
        <tr className=" gap-x-2	" >
           <td>
           <a href="/Our_Portfolio"> <Button className="bg-blue-600"> <i style={{fontSize:14}} class="fa-solid fa-eye"></i> View Clients </Button></a>
           </td>
           <td>
           <a href="/Businessform_apply">  <Button className="bg-green-500 "> Apply <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
 </td>
 
 
         </tr></div>
        
       </CardFooter>
       
       </Card></div>
 
 
 {/* Corporate Plan    */}
 
       <div className="p-[5px] mt-[10px]">  
 <Card  variant="gradient" className="p-[12px] bg-blue-900	
      mx-auto rounded ">
           <CardHeader color="blue-gray" className="auto w-cover h-15">
          <img
            src="./assets/plans/Corporate Plan.webp"
          />
        </CardHeader>
           
           <CardBody className="mt-[-20px] bg-blue-900" >
             
         <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal">
            <span className="mt-2 ml-2 text-[32px]">₹70,000  </span>{""}
            <span className="self-end text-[14px]">   / Yearly  </span> 
            <div className="text-center" class="flex gap-10">
   <div
      class="ml-5 relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
      <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
        <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
      </div> 
      <span class="ml-5"> <p class="mr-[9px] text-yellow-200 text-[22px] line-through ..."> ₹90,000 </p> </span>  
    </div>
  
  </div>  
          </Typography>
          <Accordion >
         <AccordionHeader className="text-white"> </AccordionHeader> 
       </Accordion>
   <table class="ml-[-40px] border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
  dark:text-gray-400"> 
       
       <tbody>
  {/*   1st  Section  */}
             <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">
 
             <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">App Development <div
     class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
     <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  <i style={{fontSize:10}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
   </div>
 
  </Typography>
            </li>
                    </Typography>
                  </td>
   
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                     <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
  </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
 
              {/*  2nd section  */}
 
               <tr> 
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
  </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white  text-[14px] font-normal">
              Banner Ads    
              </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
               
 {/* 3rd section  */}
               <tr>
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">
              Video Ads Creation
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className=" flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">
             Domain & Hosting   
              </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
               
 {/* 4th section  */}
               <tr>
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal ">
              Upgrade Version+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through ">
              Full Stack Feature+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
               </tr>
               
               {/* 5th Section */} 
 
               <tr >
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-red-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through">
              Advanced Customization +
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through">
              Company Formation+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
               </tr>
               </tbody>
               
               
               
               </table>
   
   
       </CardBody>
       <CardFooter className=" mt-[-20px]" >
       <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
        <tr className=" gap-x-2	" >
           <td>
           <a href="/Our_Portfolio"> <Button className="bg-blue-600"> <i style={{fontSize:14}} class="fa-solid fa-eye"></i> View Clients </Button></a>
           </td>
           <td>
           <a href="/Corporateform_apply">  <Button className="bg-green-500 "> Apply <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
 </td>
 
 
         </tr></div>
        
       </CardFooter>
       
       </Card></div>
 
 
 {/* Custom Plan    */}
 
 <div className="p-[5px] mt-[10px]">  
 <Card  variant="gradient" className="p-[12px] bg-blue-900	
      mx-auto rounded ">
           <CardHeader color="blue-gray" className="auto w-cover h-15">
          <img
            src="./assets/plans/Custom Plan.webp"
          />
        </CardHeader>
           
           <CardBody className="mt-[-20px] bg-blue-900" >
             
         <Typography
            variant="h1"
            color="white"
            className="mt-6 flex justify-center gap-1 text-7xl font-normal">
            <span className="mt-2 ml-2 text-[32px]">₹1,50,000  </span>{""}
            <span className="self-end text-[14px]">   / Yearly  </span> 
            <div className="text-center" class="flex gap-10">
   <div
      class="ml-5 relative grid items-center px-1 py-1 font-sans text-xs font-bold text-green-100 uppercase rounded-md select-none whitespace-nowrap bg-yellow-600">
      <div class="absolute w-4 h-4 top-2/4 left-1 -translate-y-2/4">
        <span class="mx-auto mt-1 block h-2 w-2 rounded-full bg-yellow-300 content-['']"></span>
      </div> 
 
      <span class="ml-5"> <p class="mr-[2px] text-yellow-200 text-[12px] line-through ..."> ₹2 Lac </p> </span> 
 </div>
  
  </div>
          </Typography>
          <Accordion >
         <AccordionHeader className="text-white"> </AccordionHeader> 
       </Accordion>
   <table class="ml-[-40px] border-separate [border-spacing:0.75rem] w-full text-sm text-left rtl:text-right text-gray-500
  dark:text-gray-400"> 
       
       <tbody>
  {/*   1st  Section  */}
             <tr class="bg-transparent  dark:bg-gray-800 dark:border-gray-700">
 
             <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">App Development <div
     class="text-[10px] relative grid select-none items-center whitespace-nowrap rounded-lg bg-white py-1 px-1 font-sans  uppercase text-black">
     <span className="text-[9px]"> <i style={{fontSize:10}} className="text-green-500 fa-brands fa-android "></i>  <i style={{fontSize:10}} className="fa-brands text-blue-700 fa-app-store-ios ml-1 p-1"></i> Android & iOS  </span>
   </div>
 
  </Typography>
            </li>
                    </Typography>
                  </td>
   
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                     <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] mr-4 font-normal"> Website Development 
  </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
 
              {/*  2nd section  */}
 
               <tr> 
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px]  font-normal">Digital Marketing 
  </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white  text-[14px] font-normal">
              Banner Ads    
              </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
               
 {/* 3rd section  */}
               <tr>
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">
              Video Ads Creation
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className=" flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal">
             Domain & Hosting   
              </Typography>
            </li>
                    </Typography>
                  </td>
 
               </tr>
               
 {/* 4th section  */}
               <tr>
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal ">
              Upgrade Version+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through ">
              Full Stack Feature+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
               </tr>
               
               {/* 5th Section */} 
 
               <tr >
 
               <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-red-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through">
              Advanced Customization +
              </Typography>
            </li>
                    </Typography>
                  </td>
  
  
                  <td>
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                    <li className="flex items-center gap-4">
              <span className="rounded-full border border-white/20 bg-teal-400 p-1">
                <CheckIcon />
              </span>
              <Typography className="text-white text-[14px] font-normal line-through">
              Company Formation+ 
              </Typography>
            </li>
                    </Typography>
                  </td>
               </tr>
               </tbody>
               
               
               
               </table>
   
   
       </CardBody>
       <CardFooter className=" mt-[-20px]" >
       <div className="gap-x-6 mt-[-15px] gap-y-6 grid-cols-2">  
        <tr className=" gap-x-2	" >
           <td>
           <a href="/Our_Portfolio"> <Button className="bg-blue-600"> <i style={{fontSize:14}} class="fa-solid fa-eye"></i> View Clients </Button></a>
           </td>
           <td>
           <a href="/Customform_apply">  <Button className="bg-green-500 "> Apply <i style={{fontSize:13}} class="fa-solid fa-angles-right"></i></Button></a>
 </td>
 
 
         </tr></div>
        
       </CardFooter>
       
       </Card></div>
 
 
  
 </Carousel>
  
   </section>
 
   
    
    </>
   )
 }
  
 