/* eslint-disable no-unused-vars */
import React from 'react'
import Navbarsection from '../views/Home/Navbarsection'
 import App_development_single from './Single/App_development_single'
import Website_development_single from './Single/Website_development_single'
import Digital_marketing_single from './Single/Digital_marketing_single'
import Banner_ads_single from './Single/Banner_ads_single'
import Logo_design_single from './Single/logo_design_single'
import Video_ads_single from './Single/Video_ads_single'
import Footersection from '../views/Home/Footersection'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Plans from '../views/Home/Plans'
import Plansdetails from '../views/Home/Plansdetails'

export default function AllServices() {
  return (
   <>
   <Navbarsection/> 

   <Tabs>
    <TabList>
      <Tab> <b className='text-blue-700' > App Development</b> </Tab>
      <Tab> <b className='text-orange-600' > Website Development </b> 
 </Tab>
      <Tab> <b className='text-green-600' >  Digital Marketing </b>
 </Tab>
      <Tab> <b className='text-pink-500' >  Banner Ads </b>   </Tab>
      <Tab> <b className='text-purple-500' >  Video Ads</b>    </Tab>
      <Tab> <b className='text-red-600' >  Logo Design </b>     </Tab>
      <Tab><b className='text-blue-900' >  Our Plans </b>    </Tab>  
    </TabList>

    <TabPanel>
    <App_development_single/>
    </TabPanel>


    <TabPanel>
    <Website_development_single/>
    </TabPanel>


    <TabPanel>
    <Digital_marketing_single/>
    </TabPanel>


    <TabPanel>
    <Banner_ads_single/>
    </TabPanel>

    <TabPanel>
    <Video_ads_single/>
    </TabPanel>

    <TabPanel>
    <Logo_design_single/>
    </TabPanel>

    <TabPanel>
   <Plansdetails/>
    </TabPanel>

  </Tabs>
  
  
   <br></br> 
   <br></br>  <br></br> 
 <Footersection/>
    </>
  )
}
