/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unknown-property */

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
 


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect } from "react"
import Glide from "@glidejs/glide"
import Navbarsection from "../views/Home/Navbarsection";
import Footersection from "../views/Home/Footersection";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 2000, min: 1000 },
    items: 4
  },
  desktop: {
    breakpoint: { max: 2000, min: 900 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items:1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};
// eslint-disable-next-line react-refresh/only-export-components
function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="h-3 w-3"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
}
export default function Portfolio_section_hickter() {
  return (
   <>
   <section className="mt-[4px]">
   <div class="mx-auto">
      <div  class="mb-[22px] text-gray-600 body-font  ">
      <h3 class="text-center lg:text-[22px] font-bold sm:text-2xl"> Our Portfolio   <a href="/Our_Portfolio">  <Button className="bg-blue-600">   View All  <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button> </a> </h3>
        </div>
      </div>
 

      <Carousel responsive={responsive}  className="z-[0] overscroll-x-none P-4" >
    {/* End Portfolio 0   */}
  
     {/* Start Portfolio 2  */}
     <div className="p-1">
  <Card className="mb-[10px]  p-[4px] mt-0 w-90">
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src="./assets/portfolio/bh.png"
            alt="" />
        </CardHeader>
  
        <CardBody className="mb-[-22px] ">
          <Typography variant="h5" color="blue-gray" className="mb-2">
          BANDAARI MEDICAL SERVICES
          </Typography>
          <Typography>
          Our core expertise lies in catering to the unique healthcare needs of international patients, with a special focus on clientele from African and Middle Eastern nations.
           </Typography>
        </CardBody>
  
        <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
  
     
              <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-php"></i>
              </span>
         
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-wordpress"></i>
              </span>
        
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-html5"></i>
              </span>
           
            
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-css3"></i>
              </span>
           
          </div>
          <CardFooter className="mt-[-16px]">
         <a href="https://bandariglobalhealthcare.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
        </CardFooter>
      </Card>
  </div>
   {/* End  Portfolio 2  */}


    {/* Start Portfolio 2  */}
    <div className="p-1">
  <Card className="mb-[30px]  p-[4px] mt-0 w-90">
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src="./assets/portfolio/BT.png"
            alt="" />
        </CardHeader>
  
        <CardBody className="mb-[-22px] ">
          <Typography variant="h5" color="blue-gray" className="mb-2">
          Brother Printer Service Center |  Printing Services 
          </Typography>
          <Typography>
          We Have Well Qualified Specialists To Handle Your Toner Cartridges With Excessive Care Of Your Printers.
           </Typography>
        </CardBody>
  
        <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
  
     
              <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-php"></i>
              </span>
         
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-wordpress"></i>
              </span>
        
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-html5"></i>
              </span>
           
            
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-css3"></i>
              </span>
           
          </div>
          <CardFooter className="mt-[-16px]">
         <a href="https://brotherprinterservicecenter.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
        </CardFooter>
      </Card>
  </div>
   {/* End  Portfolio 2  */}


    {/* Start Portfolio 2  */}
<div className="p-1">
  
  <Card className="mb-[30px]  p-[4px] mt-0 w-90">
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src="./assets/portfolio/rd.png"
            alt="Auragoa | SPA & Living" />
        </CardHeader>
  
        <CardBody className="mb-[-22px] ">
          <Typography variant="h5" color="blue-gray" className="mb-2">
          Radhikarajpurohit.com 
          </Typography>
          <Typography>
          Clothing brand stores offer a unique shopping experience that is unlike any other. These stores typically sell a variety of clothing items from a single brand.
           
           </Typography>
        </CardBody>
  
        <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
  
     
              <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-php"></i>
              </span>
         
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-wordpress"></i>
              </span>
        
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-html5"></i>
              </span>
           
            
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-css3"></i>
              </span>
           
          </div>
          <CardFooter className="mt-[-16px]">
         <a href="https://radhikarajpurohit.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
        </CardFooter>
      </Card>
  </div>
   {/* End  Portfolio 2  */}
 

   {/* Start Portfolio 2  */}
<div className="p-1">
  
  <Card className="mb-[30px]  p-[4px] mt-0 w-90">
        <CardHeader color="blue-gray" className="relative h-56">
          <img
            src="./assets/portfolio/bb.png"
            alt="Auragoa | SPA & Living" />
        </CardHeader>
  
        <CardBody className="mb-[-22px] ">
          <Typography variant="h5" color="blue-gray" className="mb-2">
         Brand Basket 
          </Typography>
          <Typography>
          Consumer Brand  offer a unique shopping best brand products available on this store variety of clothing items from a single brand.
           
           </Typography>
        </CardBody>
  
        <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 
  
     
              <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-php"></i>
              </span>
         
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-wordpress"></i>
              </span>
        
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-html5"></i>
              </span>
           
            
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
              <i style={{fontSize:30}} class="fab fa-css3"></i>
              </span>
           
          </div>
          <CardFooter className="mt-[-16px]">
         <a href="https://brandbasket.store/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
        </CardFooter>
      </Card>
  </div>
   {/* End  Portfolio 2  */}

 
  {/* Start Portfolio 2  */}
<div className="p-1">
  
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/portfolio/Auragoa.webp"
          alt="Auragoa | SPA & Living" />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Auragoa | SPA & Living 
        </Typography>
        <Typography>
        This wellness oasis is an hour and a half’s drive from Goa International Airport.  Arambol beach, also very close to the property options Available . 
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 

   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://auragoa.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 2  */}

  {/* Start Portfolio 3  */}
<div className="p-1">
  
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/portfolio/voxtron.webp"
          alt="Voxtron | Digital Solution"
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Voxtron | Digital Solution 
        </Typography>

        <Typography>
        Application development company with 20+ years of experience, we build highly secure, scalable and robust applications for global businesses.  
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 


   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fa-brands fa-js"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.voxtronme.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 3  */}
 
 
 {/* Start Portfolio 5  */}
 <div className="p-1">
  
  
 
 <Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/portfolio/fiji.webp"
          alt="Fiji Water – Brand Site 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Fiji Water – Brand Site 
 
        </Typography>
        <Typography>
        FIJI Water has been committed to doing business responsibly and seeking opportunities to make a difference. Local communities 
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 


   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fa-brands fa-js"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.fijiwater.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 5  */}

 {/* Start Portfolio 6  */}
 <div className="p-1">
  
  
 
 <Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/portfolio/st.webp"
          alt=" WorkThere – Co-Working Space 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Star Reliable Book Keeping & Payroll Services
        </Typography>
        <Typography>
        It involves recording transactions and storing financial documentation to manage the overall financial health of an organization. Most businesses use electronic methods for their bookkeeping, whether it’s a simple spreadsheet or more advanced, specialized software.
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 

            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-wordpress"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.starreliablebookkeepingandpayrollservices.com/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 6  */}

  {/* Start Portfolio 7  */}
<div className="p-1">
  
  
 
<Card className="mb-[30px]  p-[4px] mt-0 w-90">
      <CardHeader color="blue-gray" className="relative h-56">
        <img
          src="./assets/portfolio/Asset Homes.png"
          alt="Asset Homes – Real estate Home 
          "
        />
      </CardHeader>

      <CardBody className="mb-[-22px] ">
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Asset Homes – Real estate Home 
 
        </Typography>
        <Typography>
        We are one of Kerala’s premier home builders, having completed several projects on time and within budget.Get all deals of property at best rate possible for you. 
         
         </Typography>
      </CardBody>

      <div className="group mb-[-2px] ml-5  inline-flex flex-wrap items-center  gap-7"> 


   
            <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-php"></i>
            </span>
       
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-laravel"></i>
            </span>
      
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-html5"></i>
            </span>
         
          
          <span className="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-3 text-gray-900 transition-colors hover:border-gray-900/10 hover:bg-gray-900/10 hover:!opacity-100 group-hover:opacity-70">
            <i style={{fontSize:30}} class="fab fa-css3"></i>
            </span>
         
        </div>
        <CardFooter className="mt-[-16px]">
       <a href="https://www.assethomes.in/" target="_blank"> <Button className="bg-blue-700 ">   View Website <i style={{fontSize:12}} class="fas fa-chevron-right"></i></Button></a> 
      </CardFooter>
    </Card>
</div>
 {/* End  Portfolio 7 */}


   


</Carousel>

  </section>

    
   </>
  )
}
  