/* eslint-disable no-unused-vars */
import React from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react"; 
 
export default function All_portfolio_hickter() {
  return (
   <>
 
 <div className="mt-[10px]">
   <h2 className=" w-1/1 p-5 mx-auto text-center font-bold text-[18px]"> Our Portfolio - Works </h2>

  </div>

  {/* Bhumiratna  */}

   <section className="mt-[-50px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/mazgon.png"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        magzonindia.com  
        </Typography>
        <Typography>
        Micro Nutrient Fertilizer ,Soil Fungus And Becterial Control in India. The supplier company is located in PUNE, Maharashtra and is one of the leading sellers of listed products. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.magzonindia.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  <td>    </td>
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Pimpri , Pune ( India )
                </div>
            </div>
        </div>
    </article>
</section>

  {/* startreliable  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/st.webp"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className=" p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
         
        </Typography>
        <Typography>
        Star Reliable Book Keeping & Payroll Services |  We work with quickbooks, xero, axon, and sage 50. We work 24/7 so you can reach out to us anytime.Maintaining a business’s financial records.  
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.starreliablebookkeepingandpayrollservices.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Saskatoon SK  ( Canada )
                </div>
            </div>
        </div>
    </article>
</section>


{/* radhikarajpurohit    */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/rd.png"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        radhikarajpurohit.com
        </Typography>
        <Typography>
        Clothing brand stores offer a unique shopping experience that is unlike any other. These stores typically sell a variety of clothing items from a single brand.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
               
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://radhikarajpurohit.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Kantabanji, Odisha  ( India )
                </div>
            </div>
        </div>
    </article>
</section>


{/* brandbasket.store    */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/bb.png"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        brandbasket.store
        </Typography>
        <Typography>
      Consumer Brand  offer a unique shopping best brand products available on this store variety of clothing items from a single brand.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
               
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://brandbasket.store/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place:  Raipur ( India )
                </div>
            </div>
        </div>
    </article>
</section>



 {/* bandariglobalhealthcare.com |  
 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/bh.png"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
       Bandhari Global Healthcare
        </Typography>
        <Typography>
        Our core expertise lies in catering to the unique healthcare needs of international patients, with a special focus on clientele from African and Middle Eastern nations.  </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                 
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://bandariglobalhealthcare.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Hyderabad ( India )
                </div>
            </div>
        </div>
    </article>
</section>
 
 {/* istech |  
 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Isyx Tech.webp"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        ISYX Tech – Tech Solution
        </Typography>
        <Typography>
        The company was founded in 2011 . 10+ Global Offices, we help businesses to impact on their customers by designing, implementing and managing infrastructure. 
        </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                 
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://isyxtech.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: India
                </div>
            </div>
        </div>
    </article>
</section>




 {/* BT |  
 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/BT.png"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Brother Printer Service Center |  Printing Services 
        </Typography>
        <Typography>
        We Have Well Qualified Specialists To Handle Your Toner Cartridges With Excessive Care Of Your Printers.  </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                <div className="sm:flex sm:justify-between">
                 
                     
                </div>
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://brotherprinterservicecenter.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Al Awamer ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>



{/* Hotel Prince  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/hp.webp"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
       Hotel Prince |  Hotel & Room Booking 
        </Typography>
        <Typography>
        This hotel in Odisha offer a number of benefits to customers. They are affordable, convenient, safe, and supportive. They also offer a number of specific benefits. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://hotelprince.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Bhagya Nagar Colony Rd, Hyderabad  ( India )
                </div>
            </div>
        </div>
    </article>
</section>


{/* Fiji Water – Brand Site  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/fiji.webp"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Fiji Water – Brand Site
        </Typography>
        <Typography>
        FIJI Water has been committed to doing business responsibly and seeking opportunities to make a difference. Local communities, while focusing on meaningful issues. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
               
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.fijiwater.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Blvd Floor, 
Los Angeles ( CA )
                </div>
            </div>
        </div>
    </article>
</section>

 
{/* Voxtron | Digital Solution
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/voxtron.webp"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Voxtron | Digital Solution 
        </Typography>
        <Typography>
        Application development company with 20+ years of experience, we build highly secure, scalable and robust applications for global businesses. 
 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.voxtronme.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/* <td> <a href="https://play.google.com/store/apps/details?id=com.bhumiratnaagriscience.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td> */}
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mattar Street, Najma, Airport Road Doha ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>
 

 {/* Money Vision | Mutual Fund */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/mv.webp"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
          
          
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Money Vision | Mutual Fund

        </Typography>
        <Typography>
        Mutual fund apps offer a number of benefits to investors of all experience levels. They provide a convenient, simple, and affordable way to invest


         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
            
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://moneyvision.in/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
   
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mattar Street, Najma, Airport Road Doha ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>

  
 {/* Luxury Perfume | Brand 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Luxury Perfume.webp"
          alt="Bhumiratna" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Luxury Perfume | Brand 
        </Typography>
        <Typography>
        This is the best place to buy perfumes and colognes online. We offer a wide selection of fragrances from top brands, including Chanel, Dior, Gucci,etc.
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
               
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://luxuryperfume.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Broadway,  ( Los Angeles )
                </div>
            </div>
        </div>
    </article>
</section>
 

 
{/* FRATERNITY TECHNOCRATS 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/ft.webp"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        FRATERNITY TECHNOCRATS | Solar Renewal Energy
        </Typography>
        <Typography>
        Fraternity Technocrats encourage our community to be environmentally conscious and to preserve our world for ourselves and future generations. FT is dedicated to enlightening society. 

         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
             
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.fraternitytechnocrats.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Bhubaneswar, Odisha  ( India )
                </div>
            </div>
        </div>
    </article>
</section>
 
 
  {/* Auragoa | SPA & Living
 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Auragoa.webp"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Auragoa | SPA & Living
        </Typography>
        <Typography>
        This wellness oasis is an hour and a half’s drive from Goa International Airport. Arambol beach, also very close to the property options Available .
 
 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
                
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://auragoa.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Mandrem, Goa ( India )
                </div>
            </div>
        </div>
    </article>
</section>


 {/* jerseygroup |  
 
  */}
<section className="mt-[-60px] container mx-auto p-10 md:p-20 antialiased ">
    <article
        className=" flex flex-wrap md:flex-nowrap shadow-lg mx-auto max-w-3xl group cursor-pointer transform duration-500 hover:-translate-y-1">
       <img
          src="./assets/portfolio/Jersey Group.png"
          alt="" className="relative mx-auto rounded-lg overflow-hidden w-full max-h-[365px] object-cover md:w-52"  />
           
        <div className="">
            <div className="p-5 pb-10">
            <CardBody className='mt-[-35px]' >
        <Typography variant="h5" color="blue-gray" className="mb-2">
        Jersey Group |  Projects Builder For National Recognition
        </Typography>
        <Typography>
        Jersey Group is one amongst the foremost group of companies offering world leading MEP products & services, firmly established in Qatar. 
         </Typography>
      </CardBody>
            </div>
            <div className="mt-[-70px] bg-blue-50 p-5">
               
                <CardFooter className="pt-0">
      <tr>
  <td>  <a href="https://www.jerseygroup.com/" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   Website <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>
  {/*  <td> <a href="https://play.google.com/store/apps/details?id=com.bookmyhome.com" target="_blank" rel="noreferrer"> <Button className="bg-blue-700 ">   App <i style={{fontSize:12}} className="fas fa-chevron-right"></i></Button></a>  </td>  */} 
</tr>
      </CardFooter>
                <div className="mt-[-12px] text-gray-600 text-sm md:text-sm">
                    * Place: Al Awamer ( Qatar )
                </div>
            </div>
        </div>
    </article>
</section>

 
   </>
  )
}
